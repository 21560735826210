import WCCAttachment, {JSONWCCAttachment} from "./attachment";
import {Observable} from "knockout";
import autobind from "decorators/autobind";
import {ResponsesThread} from "../../pages/admin/discussions/projectDashboard/pages/responses/models/responsesThread";

export interface JSONWCCTranscribableAttachment extends JSONWCCAttachment {
    TranscriptionEnabled?: boolean
    TranscriptStatus?: number
    TranscriptContent?: string
    
    TopicThreadId?: string
}

export default class WCCTranscribableAttachment extends WCCAttachment<JSONWCCTranscribableAttachment> {
    
    busy = ko.observable(false);
    
    threadId: Observable<string | undefined>;
    thread: ResponsesThread | undefined;
    
    transcriptionEnabled: Observable<boolean>;
    transcriptStatus: Observable<number>;
    transcriptContentParsed = ko.observable<string>('');
    hasTranscription = ko.pureComputed(() => !!this.transcriptContent());
    transcriptionPending = ko.pureComputed(() => this.transcriptStatus() === WCC.Enums.ThreadTranscriptionStatuses.TranscriptionPending.value);

    transcriptBaseClass = ko.pureComputed(() => {
        let baseClass = '';
        switch (this.transcriptStatus()) {
            case WCC.Enums.ThreadTranscriptionStatuses.unknown.value:
            case WCC.Enums.ThreadTranscriptionStatuses.NoTranscription.value:
                baseClass = 'default';
                break;
            case WCC.Enums.ThreadTranscriptionStatuses.TranscriptionPending.value:
                baseClass = 'warning';
                break;
            case WCC.Enums.ThreadTranscriptionStatuses.Accurate.value:
                baseClass = 'success';
                break;
            default:
                baseClass = 'info';
                break;
        }
        return baseClass;
    });
    transcriptButtonClass = ko.pureComputed(() => {
        return 'dropdown-toggle btn btn-sm btn-' + this.transcriptBaseClass();
    });
    transcriptLabelClass = ko.pureComputed(() => {
        return 'label label-' + this.transcriptBaseClass();
    });

    hasMOVFile = ko.pureComputed(() => !!_.find(this.files(), f => f.extension()?.toLowerCase() === 'mov'));
    generateTranscriptText = ko.pureComputed(() => this.hasMOVFile() ? 'Convert Video & Generate Transcript' : 'Generate Transcript');

    transcriptButtonText = ko.pureComputed(() => {
        let buttonText = '';
        switch (this.transcriptStatus()) {
            case WCC.Enums.ThreadTranscriptionStatuses.unknown.value:
            case WCC.Enums.ThreadTranscriptionStatuses.NoTranscription.value:
                buttonText = 'Not Transcribed';
                break;
            case WCC.Enums.ThreadTranscriptionStatuses.TranscriptionPending.value:
                if (WCC.Settings.UseNewVideoUpload) {
                    buttonText = 'Pending';
                }
                else {
                    if (this.hasMOVFile()) {
                        buttonText = 'MOV not supported';
                    } else {
                        buttonText = 'Pending';
                    }
                }
                break;
            case WCC.Enums.ThreadTranscriptionStatuses.FirstEdit.value:
            case WCC.Enums.ThreadTranscriptionStatuses.BeingEdited.value:
            case WCC.Enums.ThreadTranscriptionStatuses.Edited.value:
                buttonText = 'Edited';
                break;
            case WCC.Enums.ThreadTranscriptionStatuses.Accurate.value:
                buttonText = 'Accurate';
                break;
            default:
                buttonText = 'Transcribed';
                break;
        }
        return buttonText;
    });

    constructor(data?: JSONWCCTranscribableAttachment) {
        super(data);

        this.transcriptionEnabled = this.createField(data, 'TranscriptionEnabled', false);
        this.transcriptStatus = this.createField(data, 'TranscriptStatus', enums.ThreadTranscriptionStatuses.NoTranscription.value);
        this.transcriptContent = this.createField(data, 'TranscriptContent');
        
        this.threadId = this.createField(data, 'TopicThreadId');
    }


    @autobind
    clearTranscription(status: number) {
        this.transcriptContent('');
        this.transcriptStatus(status);
    }

    @autobind
    async generateTranscript() {
        if (this.busy()) {
            return;
        }

        const generateFn = async () => {
            this.busy(true);

            try {
                await WCC.API.apiRequest({
                    url: `threads/${this.threadId()}/attachments/${this.attachmentId()}/generatetranscript`,
                    method: 'POST',
                });
                this.clearTranscription(WCC.Enums.ThreadTranscriptionStatuses.TranscriptionPending.value);
            }
            catch(e) {
                system.showFailedRequestMessage(e);
            }

            this.busy(false);
        };

        if (this.hasTranscription()) {
            WCC.System.confirm('Are you sure you to regenerate transcription for this video?', generateFn);
        }
        else if (this.transcriptStatus() == WCC.Enums.ThreadTranscriptionStatuses.TranscriptionPending.value) {
            WCC.System.confirm('Transcription for this video is already pending. Are you sure you want to send another request?', generateFn);
        }
        else {
            await generateFn();
        }
    }

    @autobind
    deleteTranscript() {
        if (this.busy()) {
            return;
        }

        WCC.System.confirm('Are you sure you to delete transcription for this video?', async () => {
            this.busy(true);

            try {
                await WCC.API.apiRequest({
                    url: `threads/${this.threadId()}/attachments/${this.attachmentId()}/transcript`,
                    method: 'DELETE',
                });
                this.clearTranscription(WCC.Enums.ThreadTranscriptionStatuses.NoTranscription.value);
            }
            catch(e) {
                system.showFailedRequestMessage(e);
            }

            this.busy(false);
        });
    }
    
    @autobind
    editTranscript() {
        //attachment.thread.analysisVM.transcriptionEditorVM.showTranscriptionEditor(attachment, () => attachment.thread.reload());
        WCC.Reactor.dispatchEvent('EditTranscriptRequested', this);
    }
}