import { BlobWrapper } from 'interfaces/blobWrapper';

const { allowedUploadVideoExtensions } = settings;

const $body = $('body');
const extPattern = /^\w+$/;

let $control: JQuery<HTMLInputElement> | undefined;

export module FilesHelpers {
    /**
     * Creates hidden file control which can be used to pick files
     * @param allowMultiple do we allow to pick multiple files
     * @param extensions file extensions we allow to pick. all if not specified
     */
    export function getFileControl(allowMultiple = false, allowCameraCapture = false, extensions?: Array<string>, container?: JQuery) {
        const $control: JQuery<HTMLInputElement> = $('<input type="file" style="position: absolute; top: 0; left: 0; margin: -100% 0 0 -100%;" />');

        if (allowMultiple)
            $control.attr('multiple', '');

        if (extensions) {
            let accept = extensions.join(', ');

            if (allowCameraCapture) {
                // possible fix for android devices not showing camera when picking files
                if(system.isMobileDevice())
                    accept = accept.replace(/[.]/g, 'video/') + (system.isMobileDevice() ? ';capture=camera' : '');
                else
                    accept += ', ' + accept.replace(/[.]/g, 'video/');

                //console.log(accept);

                //$control.attr('capture', 'environment');
            }

            $control.attr('accept', accept);
        }

        if (container != undefined)
            container.append($control);
        else
            $body.append($control);

        return $control;
    }

    export function pick(allowMultiple = false, allowCameraCapture = false, extensions?: Array<string> | string) {
        let targetExtensions = _.isString(extensions) ? [extensions] : extensions ?? [];

        targetExtensions = targetExtensions.map(ext => {
            if (extPattern.test(ext))
                return ext.startsWith('.') ? ext : `.${ext}`;

            return ext;
        });

        return new Promise<Array<File>>(resolve => {
            if ($control != undefined)
                $control.remove();

            $control = getFileControl(allowMultiple, allowCameraCapture, targetExtensions);

            const control = $control[0];

            $control.change(() => {
                if (control.files)
                    resolve(Array.from(control.files));
            });

            $control.click();
        });
    }

    export async function pickFile(allowCameraCapture = false, extensions?: Array<string> | string) {
        const files = await pick(false, allowCameraCapture, extensions);

        return files[0];
    }

    export function pickFiles(extensions?: Array<string> | string) {
        return pick(true, false, extensions);
    }

    export async function pickImage() {
        return await pickFile(false, 'image/*, .heic');
    }

    export async function pickImages() {
        return await pickFiles('image/*, .heic');
    }

    export async function pickVideo() {
        return await pickFile(true, 'video/*');
    }

    export function getExtension(file: File | BlobWrapper) {
        return system.getExtension(file.name);
    }

    export async function loadAsBlob(url: string, headers: StringMap<string> = {}) {
        const resp = await fetch(url, { headers });

        if (resp.status == 200)
            return await resp.blob();

        throw new Error(await resp.text());
    }

    export function saveAs(blob: Blob, name: string) {
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && (<any>window.navigator).msSaveOrOpenBlob) {
            (<any>window.navigator).msSaveOrOpenBlob(blob);
            return;
        }

        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(blob);

        let link = document.createElement('a');
        link.href = data;
        link.download = name;
        link.click();

        // For Firefox it is necessary to delay revoking the ObjectURL
        _.defer(() => window.URL.revokeObjectURL(data), 100);
    }

    export function getContent(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = e => resolve((e as any).target.result);
            reader.onerror = e => reject('error during reading file');

            reader.readAsText(file, "UTF-8");
        });
    }

    export function getImageContent(fileOrBlob: File | BlobWrapper, config?: any) {
        const extension = getExtension(fileOrBlob);
        const contentType = system.getContentTypeFromExtension(extension);

        return new Promise<string>(resolve => {
            import('blueimp-load-image').then(({ default: loadImage }) => {
                loadImage('blob' in fileOrBlob ? fileOrBlob.blob : fileOrBlob, canvas => {
                    resolve((canvas as HTMLCanvasElement).toDataURL(contentType));
                }, _({ orientation: true, canvas: true }).extend(config));
            });
        });
    }

    export function getPreview(fileOrBlob: File | BlobWrapper): Promise<HTMLImageElement> {
        return Promise.resolve().then(() => {
            var extension = getExtension(fileOrBlob) ?? '';

            if (_(allowedUploadVideoExtensions).contains(extension)) {
                return getVideoPreview(fileOrBlob);
            } else {
                return getImagePreview(fileOrBlob);
            }
        }).catch(() => getImagePreview(fileOrBlob));
    }

    export function getImagePreview(fileOrBlob: File | BlobWrapper): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            var image = new Image();

            image.onload = () => resolve(image);
            image.onerror = () => reject(new Error(`Image is not available`));

            image.src = URL.createObjectURL('blob' in fileOrBlob ? fileOrBlob.blob : fileOrBlob);
        });
    }

    export function getVideoPreview(fileOrBlob: File | BlobWrapper): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            var video = document.createElement('video'),
                source = document.createElement('source');

            video.addEventListener('loadeddata', () => {
                if (video.readyState >= 2) {
                    const duration = video.duration;

                    const process = _.once(function () {
                        var canvas = document.createElement('canvas'),
                            ctx = canvas.getContext("2d");

                        canvas.width = video.videoWidth;
                        canvas.height = video.videoHeight;

                        if (ctx)
                            ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

                        var preview = new Image();

                        preview.onload = () => resolve(preview);
                        preview.onerror = ex => reject(ex);

                        preview.src = canvas.toDataURL();
                    });

                    video.addEventListener('timeupdate', process);
                    _.delay(process, 30000);

                    video.currentTime = _([1, duration]).min();
                }
            });

            source.src = URL.createObjectURL('blob' in fileOrBlob ? fileOrBlob.blob : fileOrBlob);
            video.appendChild(source);

            video.load();
            video.pause();
        });
    }

    export function base64toBlob(str: string, sliceSize = 512) {
        const [metadata, data] = str.split(',');
        const contentType = metadata.split(':')[1].split(';')[0];

        const byteCharacters = atob(data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++)
                byteNumbers[i] = slice.charCodeAt(i);

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });

        return blob;
    }

    export function blobToBase64(blob: Blob) {
        return new Promise<string>(resolve => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(<string>reader.result);
            reader.readAsDataURL(blob);
        });
    }

    /**
     * Check if file smaller then provided limit
     * @param fileOrBlob File or Blob to check
     * @param limit Limit in Kb
     */
    export function checkIfMatchesSizeLimit(fileOrBlob: File | BlobWrapper, limit: number) {
        const fileSize = 'blob' in fileOrBlob ? fileOrBlob.blob.size / 1000 : fileOrBlob.size / 1000;

        return limit == 0 || fileSize < limit;
    }

    export function isImage(file: File) {
        return file.type.startsWith('image/');
    }

    export function getSize(unit: string, sizeInBytes: number, precision: number = 2) {
        let pow = 1;

        switch (unit) {
            case 'kb':
            case 'KB':
            case 'kB':
            case 'Kb':
                pow = 1;
                break;
            case 'mb':
            case 'MB':
            case 'mB':
            case 'Mb':
                pow = 2;
                break;
            case 'gb':
            case 'GB':
            case 'gB':
            case 'Gb':
                pow = 3;
                break;
            default:
                throw new Error("Unit not supported")
        }

        return Math.ceil((sizeInBytes / Math.pow(1024, pow)) * (10 * precision)) / (10 * precision);
    }

    export function getSizeStr(unit: string, sizeInBytes: number, precision: number = 2) {
        return `${getSize(unit, sizeInBytes, precision)} ${unit}`
    }
}