import { APIHelpers } from 'helpers/api';
import { DOMHelpers } from 'helpers/dom';
import { FilesHelpers } from 'helpers/files';
import { BlobWrapper } from 'interfaces/blobWrapper';
import { Observable, ObservableArray } from 'knockout';
import 'libraries/jquery.fancyFileUploader';
import { JSONChunkUploadResponse } from 'models/chunkUploadResponse';
import WCCError from 'models/wccError';
import { WCCUploaderTransport } from '../transport';
import { WCCUploadRequestOptions } from '../uploadRequestOptions';
import { WCCS3UploadResult } from './uploadResult';

const { chunkSize } = settings;

class WCCS3ProxyUpload {
    name: Observable<string>

    chunkIdx = ko.observable(1)
    chunksCount: Observable<number>

    fileId: Observable<string | undefined> = ko.observable()
    link: Observable<string | undefined> = ko.observable()
    token: Observable<string | undefined> = ko.observable()
    uploadId: Observable<string | undefined> = ko.observable()
    chunkIds: ObservableArray<string> = ko.observableArray() 


    constructor(fileOrBlob: File | BlobWrapper) {
        const size = 'blob' in fileOrBlob ?
            fileOrBlob.blob.size :
            fileOrBlob.size;

        if (chunkSize == undefined)
            throw new Error(messages.UnknownError);

        this.name = ko.observable(fileOrBlob.name.replace(/[^\x00-\xFF]/g,''));
        this.chunksCount = ko.observable(Math.ceil(size / chunkSize));
    }

    onChunkUploaded(response: JSONChunkUploadResponse) {
        if (this.chunkIdx() == 1) {
            this.fileId(response.fileId);
            this.link(response.link);
            this.token(response.token);
            this.uploadId(response.uploadId);
        }

        this.chunkIdx.inc();
        this.chunkIds.push(response.chunkId);
    }
}

function getForm($control: JQuery): JQuery {
    return $control.data('fancy-fileupload')?.form ?? $([]);
}

export interface WCCUploaderProxyS3TransportConfig {
    allowMultiple?: boolean
    allowedExtensions?: Array<string>
    fileSizeLimit?: number
}

export default class WCCUploaderProxyS3Transport implements WCCUploaderTransport<WCCS3UploadResult> {
    constructor(private config: WCCUploaderProxyS3TransportConfig) { }

    async upload(fileOrBlob: File | BlobWrapper, { cancelPromise, progressCallback }: WCCUploadRequestOptions) {
        const $container = DOMHelpers.getHiddenContainer();
        const $control = FilesHelpers.getFileControl(this.config.allowMultiple, false, this.config.allowedExtensions, $container);

        try {
            const fileSizeLimit = this.config.fileSizeLimit ?? 0;

            if (!FilesHelpers.checkIfMatchesSizeLimit(fileOrBlob, fileSizeLimit))
                throw new WCCError(messages.FileIsTooBig.replace('{0}', fileSizeLimit.toString()));

            const result = await new Promise<WCCS3UploadResult>((resolve, reject) => {
                const upload = new WCCS3ProxyUpload(fileOrBlob);
                const isCancelled = ko.observable(false);

                cancelPromise.then(() => isCancelled(true));

                $control.FancyFileUpload({
                    url: `${settings.wccApiUrl}/api/proxy/upload`,

                    // shitcode to "auto-start upload"
                    added: function (this: JQuery) {
                        this.find('.ff_fileupload_actions button.ff_fileupload_start_upload').click();
                    },

                    continueupload: (e, data) => {
                        if (data.total > 0 && data.loaded > 0)
                            progressCallback(data.loaded / data.total * 100);

                        return !isCancelled();
                    },

                    uploadcompleted: () => {
                        const fileId = upload.fileId();
                        const key = upload.token();
                        const link = upload.link();

                        if (fileId != undefined && key != undefined && link != undefined)
                            resolve({ fileId, key, link });
                        else
                            reject(new Error(messages.UnknownError));
                    },

                    fileupload: {
                        maxChunkSize: chunkSize,
                        multipart: false,

                        beforeSend: xhr => this.setRequestHeaders(xhr, upload),
                        success: (response: JSONChunkUploadResponse) => upload.onChunkUploaded(response)
                    }
                });

                const $fileinput = getForm($control).find('input[type=file]');
                const file = 'blob' in fileOrBlob ? new File([fileOrBlob.blob], fileOrBlob.name) : fileOrBlob;

                $fileinput.fileupload('add', { files: [file] });
            });

            return result;
        } finally {
            getForm($control).remove();
            $container.remove();
        }
    }

    private setRequestHeaders(xhr: JQueryXHR, upload: WCCS3ProxyUpload) {
        const headers: Map<string, string> = new Map([
            ['x-chunk-idx', upload.chunkIdx().toString()],
            ['x-chunk-total', upload.chunksCount().toString()]
        ]);

        _(APIHelpers.getAPIHeaders()).each((value, key) => headers.set(key, value));

        if (upload.chunkIdx() == 1) {
            headers.set('x-file-name', upload.name());
        } else {
            const token = upload.token();
            const uploadId = upload.uploadId();

            if (token != undefined)
                headers.set('x-file-token', token);

            if (uploadId)
                headers.set('x-upload-id', uploadId);

            if (upload.chunkIdx() == upload.chunksCount())
                headers.set('x-chunk-ids', upload.chunkIds().join(","));
        }

        headers.forEach((value, key) => xhr.setRequestHeader(key, value));
    }
}