import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';
import {IApiUserLog, IApiUserLogCount, ILogEntry, IUserLogResult} from "../models/userLogs";
import {URLQueryData} from "../builders/url";

export class UserLogsService extends BaseService {
    private _repository = new Repository(endpoint)

    queries = {
        getLogsByPersonId: (discussionId: string, personId: string) => this._repository.query<void, IApiUserLog>(`v3/discussions/${discussionId}/participants/${personId}/userlogs`),
        getLogsByDiscussionId: (discussionId: string) => this._repository.query<void, IApiUserLog>(`v3/discussions/${discussionId}/userlogs`),
        getLogsCounts: (discussionId: string) => this._repository.query<void, IApiUserLogCount>(`v3/discussions/${discussionId}/userlogscounts`),
        createLogs: (discussionId?: string) => this._repository.postQuery<void, IUserLogResult>(`v3/discussions/${discussionId}/userlogs`),
        updateLogs: (userLogId: string) => this._repository.updateQuery<ILogEntry[], IUserLogResult>(`v3/userlogs/${userLogId}`),
        deleteLogs: (userLogId: string) => this._repository.removeQuery<IUserLogResult>(`v3/userlogs/${userLogId}`)
    }

    async getLogsByPersonId(discussionId: string, personId: string, args: URLQueryData) {
        return await this.queries.getLogsByPersonId(discussionId, personId).addArgs(args).toArray();
    }

    async getLogsByDiscussionId(discussionId: string, args: URLQueryData) {
        return await this.queries.getLogsByDiscussionId(discussionId).addArgs(args).toArray();
    }

    async getLogsCounts(discussionId: string, args: URLQueryData | null = null) {
        const qry = this.queries.getLogsCounts(discussionId);
        if (args != null) qry.addArgs(args);
        
        return await qry.toArray();
    }
    
    async createLogs(discussionId?: string) {
        return await this.queries.createLogs(discussionId).add();
    }

    async updateLogs(userLogId: string, logEntries: ILogEntry[], args: URLQueryData | null = null) {
        
        const updateLogs = this.queries.updateLogs(userLogId);
        if (args != null) updateLogs.addArgs(args);
        return await updateLogs.update(logEntries);
    }

    async deleteLogs(userLogId: string) {
        return await this.queries.deleteLogs(userLogId).remove();
    }
}