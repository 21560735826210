import { CookieHelpers } from 'helpers/cookie';
import { getSetting } from 'helpers/settings';
import { handleSuccessRequest, handleErrorRequest, beginRequest } from 'helpers/requests';
import { StringHelpers } from './string';

export module APIHelpers {
    export let token = '';
    export let accntdns = '';
    export let language = '';
    export let loginToken = '';
    export let tfaToken = '';

    export function setToken(value: string) {
        token = value;
    }

    export function setLoginToken(value: string) {
        loginToken = value
    }

    export function getAPIHeaders() {
        if (token == '') token = getSetting("CurrentUserToken");
        if (loginToken == '') loginToken = getSetting("CurrentUserLoginToken");
        if (accntdns == '') accntdns = getSetting('DNSName') ?? CookieHelpers.getCookie('DNSName');
        if (language == '') language = CookieHelpers.getCookie("Language") ?? '';
        if (tfaToken == '') tfaToken = CookieHelpers.getCookie(CookieHelpers.QUALZY_COOKIE_2FA) ?? (getSetting(CookieHelpers.QUALZY_COOKIE_2FA) ?? '');

        const result: StringMap<string> = {}

        if (token) result['x-auth'] = token;
        if (loginToken) result['x-login-auth'] = loginToken;
        if (accntdns) result['x-accntdns'] = accntdns;
        if (StringHelpers.isNotNullOrEmpty(language)) result['Accept-Language'] = language;
        if (settings.isStaging) result['x-staging'] = 'true';
        if (tfaToken) result['qzy-2fatoken'] = tfaToken;

        result['x-browser-offset'] = (-(new Date().getTimezoneOffset())) + '';

        return result;
    }

    export function apiRequestSetAuth(everything: any, additionalHeaders: any) {//override-able part that sets up the auth 
        //by default we will add the token value to the headers, this is the authentication for our API
        everything.data.beforeSend = (r: JQueryXHR) => {
            const allHeaders = _(getAPIHeaders()).extend(additionalHeaders);

            _(allHeaders).each((value, key) => r.setRequestHeader(key, value));
        }
    }

    export function apiRequestBase(url: string, data: any, additionalHeaders: any) {


        var everything = {//all our options and parameters packaged up
            data: data || {},
            url: url
        };

        //Set Defaults before calling our Set AUth function (so it can override anything it needs to)
        everything.data.type = everything.data.type || 'GET';//default to GET Request

        //run our customizable auth set up
        apiRequestSetAuth(everything, additionalHeaders);

        return $.ajax(everything.url, everything.data);//pass the ajax promise thing so we can chain our fail and success events
    }

    export function apiRequestCore(apiType: any, _url: any, requestType: any, data: any, successHandler: any, loader: any, disableContext: any, errorHandler: any, statusCodeHandlers?: any, additionalHeaders?: any, async?: any, contentType?: any, processData?: any) {

        let url,
            timeout,
            dataType;
        // if object with named properties is used instead of old calls with a list of parameters
        if (typeof _url === "object") {
            let options = _url;
            url = options.url;
            requestType = options.method || 'POST';
            data = options.data;
            dataType = options.dataType;
            successHandler = options.success;
            errorHandler = options.error;
            loader = options.loader;
            disableContext = options.context;
            additionalHeaders = options.additionalHeaders;
            statusCodeHandlers = options.statusCodeHandlers;
            async = options.async;
            contentType = options.contentType;
            processData = options.processData;
            if (options.timeout) {
                timeout = options.timeout;
            }
        }
        else {
            url = _url;
        }

        let requestData: any = data ? { data: data } : {},
            apiBaseUrl = apiType == 'WccApi' ? getSetting('WccApiUrl') : getSetting('WccCDNUrl');

        requestData.type = requestType;

        if (async != null) {
            requestData.async = async;
        }
        if (contentType != null) {
            requestData.contentType = contentType;
        }
        if(dataType) {
            requestData.dataType = dataType;
        }
        if (processData != null) {
            requestData.processData = processData;
        }
        if (timeout) {
            requestData.timeout = timeout;
        }

        requestData.success = function (xhr: any, status: any, error: any) {
            handleSuccessRequest(loader, disableContext);

            if (successHandler)
                successHandler.call(this, xhr, status, error);
        }

        requestData.error = function (xhr: any, status: any, error: any) {
            handleErrorRequest(loader, disableContext, errorHandler, xhr, status, error, false);
        }

        requestData.statusCode = statusCodeHandlers;

        beginRequest(loader, disableContext);

        return apiRequestBase(apiBaseUrl + '/api/' + url, requestData, additionalHeaders);
    }

    export function getAPIURL(url: string) {
        return `${getSetting('WccApiUrl')}/api/${url}`;
    }

    export function apiRequest(_url: any, requestType?: any, data?: any, successHandler?: any, loader?: any, disableContext?: any, errorHandler?: any) {
        return apiRequestCore('WccApi', _url, requestType, data, successHandler, loader, disableContext, errorHandler);
    }

    export function cdnApiRequest(url: any, requestType: any, data: any, successHandler: any, loader: any, disableContext: any, errorHandler: any, additionalHeaders: any) {
        return apiRequestCore('WccCDNApi', url, requestType, data, successHandler, loader, disableContext, errorHandler, additionalHeaders)
    }

    export function apiRequestV3(options: any) {
        if((options?.method == 'POST' || options?.method == 'PUT') && options.data) {
            options.contentType = 'application/json; charset=utf-8';
            options.dataType = 'json';
            options.data = JSON.stringify(options.data);
        }
        return apiRequest(options);
    }
}