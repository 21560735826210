import { FilesHelpers } from 'helpers/files';
import { PureComputed, Subscribable } from 'knockout';
import UserFile from 'models/userFile';
import { JSONWCCAttachment } from './attachment';
import { DownloadableWCCAttachment } from './downloadableAttachment';
import WCCTranscribableAttachment from "./transcribableAttachment";

export default class WCCAudio extends WCCTranscribableAttachment implements DownloadableWCCAttachment {
    file: PureComputed<UserFile | undefined>
    fileUrl: Subscribable<string | undefined>
    fileName: Subscribable<string | undefined>

    constructor(data?: JSONWCCAttachment) {
        super(data);

        this.file = this.getObservableFile(enums.UserFileTypes.Audio.value);
        this.fileUrl = this.file.pluck('link');
        this.fileName = this.file.pluck('name');
    }

    public async download() {
        return await FilesHelpers.saveAs(await FilesHelpers.loadAsBlob(this.fileUrl() ?? ''), this.fileName() ?? 'wcs_audio.mp3');
    }
}