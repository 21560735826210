import { BaseDiscussion } from 'interfaces/baseDiscussion';
import { Observable, Subscribable } from 'knockout';
import WCCEntity from './entity';
import { JSONTermsAndConditionsStatement, TermsAndConditionsStatement } from './termsAndConditionsStatement';
import {EnumValue} from "../interfaces/enumValue";
import autobind from "../decorators/autobind";

const reactor = require('models/reactor');

export interface JSONDiscussion {
    DiscussionId?: string
    
    CreatorName?: string
    CreatorUserImage?: string

    Title?: string
    QuickSell?: string
    ThoroughDescription?: string
    ThoroughDescriptionSecondary?: string
    TermsAndConditions?: string
    TermsAndConditionsStatements?: string

    Type?: number
    OnlineStatus?: number
    ModeratorNotifying?: number
    ObserverAccess?: number
    ParticipantsAnonymity?: number
    ParticipantsType?: number
    EditorPagesTypes?: number
    ModeratorAccessTypes?: string
    ObserverAccessTypes?: string

    DeleteCommentTimeout?: number
    EditCommentTimeout?: number

    MediaImageToken?: string
    MediaImageOriginalFileName?: string
    MediaImageFileExtension?: string
    MediaImageHash?: string

    IconClass?: string
    IconColor?: string
    IconStyle?: number
    IconBackgroundColor?: string
    IconBorderColor?: string

    CreateDate?: string
    EditDate?: string
    VisibleAfter?: string
    VisibleBefore?: string

    NeedsTermsAndConditions?: boolean
    RequireAPassword?: boolean
    RequireTwoFactorAuth?:boolean
    ShowTour?: boolean
    HasAttachments?: boolean
    
    PostsFilteringTypeForModerators?: number
    PostsFilteringTypeForObservers?: number
    PostsFilteringTypeForParticipants?: number
}

export default class Discussion extends WCCEntity<JSONDiscussion> implements BaseDiscussion {
    discussionId: Observable<string | undefined>

    creatorName: Subscribable<string | undefined>;
    creatorImage: Subscribable<string | undefined>;

    title: Observable<string | undefined>
    quickSell: Observable<string | undefined>
    thoroughDescription: Observable<string | undefined>
    thoroughDescriptionSecondary: Observable<string | undefined>
    termsAndConditions: Observable<string | undefined>
    termsAndConditionsStatements: Observable<string | undefined>

    type: Observable<number | undefined>
    onlineStatus: Observable<number | undefined>
    moderatorNotifying: Observable<number | undefined>
    observerAccess: Observable<number | undefined>
    participantsAnonymity: Observable<number | undefined>
    participantsType: Observable<number | undefined>
    editorPagesTypes: Observable<number | undefined>
    moderatorAccessTypes: Observable<string | undefined>
    observerAccessTypes: Observable<string | undefined>

    deleteCommentTimeout: Observable<number>
    editCommentTimeout: Observable<number>

    mediaImageToken: Observable<string | undefined>
    mediaImageOriginalFileName: Observable<string | undefined>
    mediaImageFileExtension: Observable<string | undefined>
    mediaImageHash: Observable<string | undefined>

    iconClass: Observable<string | undefined>
    iconColor: Observable<string | undefined>
    iconStyle: Observable<number | undefined>
    iconBackgroundColor: Observable<string | undefined>
    iconBorderColor: Observable<string | undefined>

    createDate: Observable<string | undefined>
    editDate: Observable<string | undefined>
    visibleAfter: Observable<string | undefined>
    visibleBefore: Observable<string | undefined>

    needsTermsAndConditions: Observable<boolean>
    requireAPassword: Observable<boolean>
    requireTwoFactorAuth: Observable<boolean>
    showTour: Observable<boolean>
    hasAttachments: Observable<boolean>

    postsFilteringTypeForModerators: Observable<number | undefined>
    postsFilteringTypeForObservers: Observable<number | undefined>
    postsFilteringTypeForParticipants: Observable<number | undefined>

    imageURL: Subscribable<string | undefined>

    parsedModeratorAccessTypes: Subscribable<Array<string>>
    parsedObserverAccessTypes: Subscribable<Array<string>>

    isExternalSurvey: Subscribable<boolean>
    isSurvey: Subscribable<boolean>
    isHomework: Subscribable<boolean>

    parsedTermsAndConditions: Subscribable<string | undefined>
    parsedTermsAndConditionsStatements: Subscribable<Array<TermsAndConditionsStatement>>

    hasIntroductionPage: Subscribable<boolean>

    visibleAfterDate: Subscribable<Date | undefined>
    visibleBeforeDate: Subscribable<Date | undefined>
    
    constructor(data?: JSONDiscussion) {
        super();

        this.discussionId = this.createField(data, 'DiscussionId');
        
        this.creatorName = this.createField(data, 'CreatorName');
        this.creatorImage = this.createField(data, 'CreatorUserImage');

        this.title = this.createField(data, 'Title');
        this.quickSell = this.createField(data, 'QuickSell');
        this.thoroughDescription = this.createField(data, 'ThoroughDescription');
        this.thoroughDescriptionSecondary = this.createField(data, 'ThoroughDescriptionSecondary');
        this.termsAndConditions = this.createField(data, 'TermsAndConditions');
        this.termsAndConditionsStatements = this.createField(data, 'TermsAndConditionsStatements');

        this.type = this.createField(data, 'Type');
        this.onlineStatus = this.createField(data, 'OnlineStatus');
        this.moderatorNotifying = this.createField(data, 'ModeratorNotifying');
        this.observerAccess = this.createField(data, 'ObserverAccess');
        this.participantsAnonymity = this.createField(data, 'ParticipantsAnonymity');
        this.participantsType = this.createField(data, 'ParticipantsType');
        this.editorPagesTypes = this.createField(data, 'EditorPagesTypes');
        this.moderatorAccessTypes = this.createField(data, 'ModeratorAccessTypes');
        this.observerAccessTypes = this.createField(data, 'ObserverAccessTypes');

        this.editCommentTimeout = this.createField(data, 'EditCommentTimeout', -1);
        this.deleteCommentTimeout = this.createField(data, 'DeleteCommentTimeout', -1);

        this.mediaImageToken = this.createField(data, 'MediaImageToken');
        this.mediaImageOriginalFileName = this.createField(data, 'MediaImageOriginalFileName');
        this.mediaImageFileExtension = this.createField(data, 'MediaImageFileExtension');
        this.mediaImageHash = this.createField(data, 'MediaImageHash');

        this.iconClass = this.createField(data, 'IconClass');
        this.iconColor = this.createField(data, 'IconColor');
        this.iconStyle = this.createField(data, 'IconStyle');
        this.iconBackgroundColor = this.createField(data, 'IconBackgroundColor');
        this.iconBorderColor = this.createField(data, 'IconBorderColor');

        this.createDate = this.createField(data, 'CreateDate');
        this.editDate = this.createField(data, 'EditDate');
        this.visibleAfter = this.createField(data, 'VisibleAfter');
        this.visibleBefore = this.createField(data, 'VisibleBefore');

        this.needsTermsAndConditions = this.createField(data, 'NeedsTermsAndConditions', false);
        this.requireAPassword = this.createField(data, 'RequireAPassword', false);
        this.requireTwoFactorAuth = this.createField(data, 'RequireTwoFactorAuth', false);
        this.showTour = this.createField(data, 'ShowTour', false);
        this.hasAttachments = this.createField(data, 'HasAttachments', false);

        this.postsFilteringTypeForModerators = this.createField(data, 'PostsFilteringTypeForModerators');
        this.postsFilteringTypeForObservers = this.createField(data, 'PostsFilteringTypeForObservers');
        this.postsFilteringTypeForParticipants = this.createField(data, 'PostsFilteringTypeForParticipants');

        this.imageURL = this.mediaImageToken.pluck(token => `${settings.wccApiUrl}/api/resources/taskimages/${token}`);

        this.parsedModeratorAccessTypes = this.moderatorAccessTypes.pluck(str => str.trim().split(','), []);
        this.parsedObserverAccessTypes = this.observerAccessTypes.pluck(str => str.trim().split(','), []);

        this.isExternalSurvey = this.type.is(enums.DiscussionType.ExternalSurvey.value);
        this.isSurvey = this.type.is(enums.DiscussionType.Survey.value);
        this.isHomework = this.type.is(enums.DiscussionType.Homework.value);

        this.parsedTermsAndConditions = this.termsAndConditions.pluck(str => marked(str));
        this.parsedTermsAndConditionsStatements = this.termsAndConditionsStatements.pluck(json => this.buildTermsAndConditionsStatements(json), []);

        this.hasIntroductionPage = this.editorPagesTypes.is(type => type != 0);

        this.visibleAfterDate = this.visibleAfter.pluck(date => new Date(date));
        this.visibleBeforeDate = this.visibleBefore.pluck(date => new Date(date));
    }

    private buildTermsAndConditionsStatements(json: string): Array<TermsAndConditionsStatement> {
        try {
            return (<Array<JSONTermsAndConditionsStatement>>JSON.parse(json)).map(s => new TermsAndConditionsStatement(s));
        } catch {
            return [];
        }
    }
}

export interface JSONDiscussionWithStatus extends JSONDiscussion {
    VisibleAfterUTC: string | null
    VisibleBeforeUTC: string | null
    PaymentStatus: number
    DiscussionStage: number
    DiscussionNotesCount: number
}

export class DiscussionWithStatus extends Discussion {
    
    paymentStatus: number;

    oDiscussionStage = ko.observable(enums.DiscussionStages.AwaitingGuide);
    discussionStages = _.map(enums.DiscussionStages, ds => ds);
    
    oDiscussionNotesCount = ko.observable(0);

    visibleAfterUTC: Observable<Date | undefined>;
    visibleBeforeUTC: Observable<Date | undefined>;

    isLive = ko.pureComputed(() => this.onlineStatus() == enums.OnlineStatusType.Open.value);
    isCompleted = ko.pureComputed(() => this.onlineStatus() == enums.OnlineStatusType.Complete.value);
    isOpening = ko.pureComputed(() => this.onlineStatus() !== WCC.Enums.OnlineStatusType.Open.value
            && this.onlineStatus() !== WCC.Enums.OnlineStatusType.Demo.value
            && this.visibleAfterUTC()
            && this.visibleAfterUTC()! > new Date());
    isOpeningSoon = ko.pureComputed(() => this.isOpening()
            && this.visibleAfterUTC() && this.visibleAfterUTC()! < new Date(new Date().setDate(new Date().getDate() + 30)));
    isDraft = ko.pureComputed(() => this.onlineStatus() == enums.OnlineStatusType.Draft.value);
    isDemo = ko.pureComputed(() => this.onlineStatus() == enums.OnlineStatusType.Demo.value);
    isOffline = ko.pureComputed(() => this.onlineStatus() == enums.OnlineStatusType.Offline.value);

    //works out where the discussion is for the arrows down the bottom in the tasks browse
    //this looks at the actual open status but also needs to deal with the 'opening soon' status
    displayStatus = ko.pureComputed(() => {
        switch (this.onlineStatus()) {
            case 2:
                return 'complete';
            case 3:
                return 'draft';
            case 4:
                return "demo";
            case 1: {//if payment status==3 the payment failed so this discussion is open temporarily and is in its 'grace period'
                return this.paymentStatus == 3 ? 'openPaymentFailed' : 'open';
            }
            default: //if its currently offline AND has a visible after date and its in the future then we are oppening soon
                // have to do date math with utc not the project changed datetime
                return this.visibleAfterUTC() != undefined && this.visibleAfterUTC()! > new Date() ? 'openingSoon' : 'offline';
        }
    });

    onlineStatusColor = ko.pureComputed(() => {
        let statusCSS = '#454545';
        switch (this.displayStatus()) {
            case 'draft':
                statusCSS = '#ee7c0e';
                break;
            case 'offline':
                statusCSS = '#e01f16';
                break;
            case 'open':
                statusCSS = '#7fb116';
                break;
            case 'demo':
                statusCSS = '#0194ce';
                break;
        }
        return statusCSS;
    });
    
    onlineStatusCSS = ko.pureComputed(() => {
        let statusCSS = 'default';
        switch (this.displayStatus()) {
            case 'draft':
                statusCSS = 'warning';
                break;
            case 'offline':
                statusCSS = 'danger';
                break;
            case 'open':
                statusCSS = 'success';
                break;
            case 'demo':
                statusCSS = 'primary';
                break;
            case 'openingSoon':
                statusCSS = 'info';
                break;
        }
        return statusCSS;
    });
    
    onlineStatusText = ko.pureComputed(() => {
        let statusText = '';
        switch (this.displayStatus()) {
            case 'draft':
                statusText = 'Draft';
                break;
            case 'offline':
                statusText = 'Offline';
                break;
            case 'open':
                statusText = 'Live';
                break;
            case 'complete':
                statusText = 'Complete';
                break;
            case 'openingSoon':
                statusText = 'Opening';
                break;
            case 'demo':
                statusText = 'In Demo';
                break;
        }
        return statusText;
    });
    
    cStageBadgeColor = ko.pureComputed(() => {
        let color = '';
        switch(this.oDiscussionStage().value) {
            case enums.DiscussionStages.AwaitingGuide.value:
                color = '#FF0000';
                break;
            case enums.DiscussionStages.Programming.value:
                color = '#FF8C00';
                break;
            case enums.DiscussionStages.AwaitingClientApproval.value:
                color = '#BF9000';
                break;
            case enums.DiscussionStages.ReadyToGoLive.value:
                color = '#39a739';
                break;
            case enums.DiscussionStages.ProjectLive.value:
                color = '#008080';
                break;
            case enums.DiscussionStages.ProjectComplete.value:
            default:
                color = '#006400'
        }
        return color;
    });
    
    cStageShortName = ko.pureComputed(() => {
        let shortName = '';
        switch(this.oDiscussionStage().value) {
            case enums.DiscussionStages.AwaitingGuide.value:
                shortName = 'AG';
                break;
            case enums.DiscussionStages.Programming.value:
                shortName = 'P';
                break;
            case enums.DiscussionStages.AwaitingClientApproval.value:
                shortName = 'AC';
                break;
            case enums.DiscussionStages.ReadyToGoLive.value:
                shortName = 'R';
                break;
            case enums.DiscussionStages.ProjectLive.value:
                shortName = 'L';
                break;
            case enums.DiscussionStages.ProjectComplete.value:
                shortName = 'C';
                break;
        }
        return shortName;
    });
    
    constructor(jsonDiscussion: JSONDiscussionWithStatus) {
        super(jsonDiscussion);
        
        this.paymentStatus = jsonDiscussion.PaymentStatus;
        
        this.setDiscussionStage(jsonDiscussion.DiscussionStage);
        
        this.oDiscussionNotesCount(jsonDiscussion.DiscussionNotesCount);
        
        this.visibleAfterUTC = ko.observable(jsonDiscussion.VisibleAfterUTC == null ? undefined : new Date(jsonDiscussion.VisibleAfterUTC));
        this.visibleBeforeUTC = ko.observable(jsonDiscussion.VisibleBeforeUTC == null ? undefined : new Date(jsonDiscussion.VisibleBeforeUTC));
    }
    
    setDiscussionStage(stageValue: number) {
        this.oDiscussionStage(_.find(enums.DiscussionStages, ds => ds.value == stageValue) ?? enums.DiscussionStages.AwaitingGuide);
    }

    @autobind
    async changeDiscussionStage(newStage: EnumValue) {
        const prevStage = this.oDiscussionStage();
        this.oDiscussionStage(newStage);
        reactor.dispatchEvent('discussionStageChanged', { discussion: this, previousStage: prevStage, newStage: newStage });

        try {
            await WCC.API.apiRequest({
                url: `discussions/${this.discussionId()}/stage/${newStage.value}`,
                method: 'PUT',
            });
        }
        catch(e) {
            system.showFailedRequestMessage(e);
            reactor.dispatchEvent('discussionStageChanged', { discussion: this, previousStage: newStage, newStage: prevStage });
            this.oDiscussionStage(prevStage);
        }
    }

}